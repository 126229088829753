/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { TentTypeV1 } from '@camp67/model';
import { Tooltip } from '../tooltip';
import { tents } from '../../constants/tents';
import clsx from 'clsx';
import Sprite from '../../sprites/sprite';
import { displayPrice } from '../../state/prices';
import { getBathingPrice, getPrice } from '@camp67/model/booking/price';
import { analyticsState, AmplitudeEvent, ButtonTag } from '../../state/amplitude';
import { TentExperienceModal } from './tent-experience-modal';
import { useMessages } from '../../i18n/useMessages';

interface TentExperienceOptionProps {
  type: TentTypeV1;
  onClick: (type: TentTypeV1, operation: Operation) => void;
  isSelected: boolean;
  isDisabled: boolean;
  showCounts?: boolean;
  availableTents?: TentTypeV1[];
  currentCount: number;
}

export type Operation = 'add' | 'subtract';

export const TentExperienceOption: React.FC<TentExperienceOptionProps> = ({
  type,
  onClick,
  isSelected,
  isDisabled,
  showCounts = true,
  availableTents,
  currentCount,
}) => {
  const {
    title: titleKey,
    displayName,
    description: descriptionKey,
    imageUrl,
    guests: guestsKey,
  } = tents[type];

  const getMessage = useMessages();
  const title = getMessage(titleKey);
  const description = getMessage(descriptionKey);
  const learnMore = getMessage('camp67-link.learn-more');
  const guests = getMessage(guestsKey);

  const [tentModalType, setTentModalType] = useState<TentTypeV1 | null>(null);

  const onTentModalClose = useCallback(() => setTentModalType(null), []);
  const onTentModalSelect = useCallback((type: TentTypeV1) => {
    analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonTag: ButtonTag.SEE_MORE_ACCOMMODATION,
      tentType: type,
    });

    setTentModalType(type);
  }, []);

  const basePrice = getPrice(type);
  const baseBathingPrice = getBathingPrice(type);

  const price = displayPrice({ priceInINR: basePrice });
  const bathingPrice = displayPrice({ priceInINR: baseBathingPrice });

  const onClickAdd = () => !isDisabled && onClick(type, 'add');
  const onClickSubtract = () => !isDisabled && onClick(type, 'subtract');

  return (
    <>
      {tentModalType ? (
        <TentExperienceModal
          tentType={tentModalType}
          onClose={onTentModalClose}
          availability={availableTents}
        />
      ) : null}
      <Tooltip
        text={
          isDisabled
            ? getMessage('camp67-reservation.form.step-1.tent-option.unavailable')
            : null
        }
        className="relative"
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <li
          className={clsx(
            'rounded-md m-0 flex flex-col items-center text-left border-2 w-full transition bg-white shadow-md-tent',
            {
              'opacity-100': !isDisabled,
              'opacity-50': isDisabled,
              'border-green-200': isSelected,
              'border-transparent': !isSelected,
            },
          )}
        >
          <img
            loading="lazy"
            src={imageUrl}
            className="w-full aspect-video object-cover rounded-t-md pointer-events-none select-none"
            // TODO: investigate how we can use `camp67-reservation.form.step-2.tent-option.image-alt` in a Trans to
            // pass the 'type' variable, except it needs to be a string.
            alt={`Displaying the tent experience offered under the ${type} offering at camp67.`}
          />
          <div className="w-full">
            <div className="flex flex-col gap-3 w-full items-start">
              <hgroup className="w-full">
                <div className="flex flex-row items-center justify-between px-4 py-3 border-b border-b-orange-100 border-dotted">
                  <p className="block text-left font-serif text-md text-black font-bold text-nowrap whitespace-nowrap w-full">
                    <span className="flex justify-between w-full flex-wrap">
                      <span>{displayName || title}</span>
                      <span className="font-medium text-right">
                        {price} {getMessage('camp67-accommodation.price-per-guest')}
                      </span>
                    </span>
                    <span className="flex flex-row flex-wrap font-sans items-start mt-0.5 justify-between gap-1.5">
                      <span className="flex flex-wrap">
                        <span className="flex mr-1 flex-row gap-1 items-center text-xs opacity-50 font-normal">
                          <Sprite
                            name="person"
                            width="20"
                            height="20"
                            className="w-3.5 min-w-3.5"
                          />
                          {guests}
                        </span>
                        <span className="flex flex-row gap-1 items-center text-xs opacity-50 font-normal">
                          <Sprite
                            name="complimentary"
                            width="14"
                            height="13"
                            className="w-3.5 min-w-3.5"
                          />
                          {getMessage('camp67-accommodation.price-meals-included')}
                        </span>
                      </span>
                      <span className="text-xs text-right mt-0.5 text-gray-400 font-normal font-sans">
                        {bathingPrice}{' '}
                        {getMessage('camp67-accommodation.price-bathing-dates')}
                      </span>
                    </span>
                  </p>
                </div>

                <p className="font-sans text-md font-normal mt-3 text-left px-4 max-w-100">
                  {description}
                </p>
              </hgroup>
            </div>
            <div className="flex flex-row flex-wrap-reverse gap-2 items-center justify-between px-4 py-4">
              <button
                className={clsx('px-4 h-10 rounded-md text-sm', {
                  'bg-[#F9F9F9] hover:bg-[#F1F1F1]': !isSelected,
                  'bg-green-50': isSelected,
                  'cursor-pointer': !isDisabled,
                  'cursor-auto': isDisabled,
                })}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onTentModalSelect(type);
                }}
                disabled={isDisabled}
              >
                {learnMore}
              </button>
              {showCounts ? (
                <div className="h-full flex flex-row items-center gap-2">
                  <span className="text-xs mr-2 text-center">{currentCount}</span>
                  <Tooltip
                    text={
                      !isDisabled &&
                      `${getMessage(
                        'camp67-reservation.form.step-1.summary.rooms.subtract.tooltip',
                      )} ${title}`
                    }
                  >
                    <button
                      type="button"
                      className={clsx(
                        'w-10 h-10 bg-[#F9F9F9] hover:bg-[#F1F1F1] rounded-sm transition-all',
                        {
                          'opacity-50': isDisabled,
                          'opacity-100': !isDisabled,
                        },
                      )}
                      onClick={onClickSubtract}
                      disabled={isDisabled}
                    >
                      -
                    </button>
                  </Tooltip>
                  <Tooltip
                    text={
                      !isDisabled &&
                      `${getMessage(
                        'camp67-reservation.form.step-1.summary.rooms.add.tooltip',
                      )} ${title}`
                    }
                  >
                    <button
                      type="button"
                      className={clsx(
                        'w-10 h-10 bg-green-150 hover:bg-green-200 rounded-sm transition-all',
                        {
                          'opacity-50': isDisabled,
                          'opacity-100': !isDisabled,
                        },
                      )}
                      onClick={onClickAdd}
                      disabled={isDisabled}
                    >
                      +
                    </button>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </li>
      </Tooltip>
    </>
  );
};
